









import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import DataContainer from "@vuetifyx/common/DataContainer";

export default Vue.extend({
  data() {
    return {
      self: this,
      country: null,
      tableOptions: null,
      importItems: [],
      importName: "",
      insertDialogContainer: new DataContainer(),
      userManager,
    };
  },
  methods: {
    makeTableOptions(country) {
      const self = this;
      const self2 = this;
      return {
        attrs: {
          "item-key": "_id",
          "sort-by": "time",
          "sort-desc": true,
        },
        content: {
          name: "Quản lý phiếu kiểm kê kho",
          urlEnabled: true,
          search: {},
          displayFields: {
            _id: {
              text: "ID",
              sortable: true,
            },
            warehouse: {
              text: "Kho",
              sortable: true,
              options: {
                subProp: "warehouse.name",
              },
            },
            time: {
              text: "Thời gian kiểm kê",
              sortableable: true,
              options: {
                filter: "dateTime",
              },
            },
            description: {
              text: "Mô tả",
            },
            itemsCount: {
              text: "Số lượng sản phẩm",
              options: {
                subProp: "items.length",
              },
            },
            createdByUser: {
              text: "Tạo bởi",
              options: {
                subProp: "createdByUser.name",
                sortBy: "createdByUserId",
              },
            },
            updatedTime: {
              text: "Tác động lần cuối",
              sortableable: true,
              options: {
                filter: "dateTime",
              },
            },
            lastUpdatedByUser: {
              text: "Tác động lần cuối bởi",
              options: {
                subProp: "lastUpdatedByUser.name",
                sortBy: "lastUpdatedByUserId",
              },
            },
            action: {},
          },
          filters: {
            filters: {
              _id: {
                attrs: {
                  label: "ID",
                },
                rules: {
                  equal_to_id: {},
                },
              },
              name: {
                attrs: {
                  label: "Name",
                },
                rules: {
                  match: {},
                },
              },
              warehouseId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Kho",
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsLoader() {
                        const { items } = await coreApiClient.call("warehouses", "findAll", {
                          payload: JSON.stringify({
                            limit: -1,
                            filters: [
                              {
                                key: "countryId",
                                operator: "equal_to",
                                value: country._id,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              time: {
                type: "XDateTimePicker",
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Ngày kiểm kê (bắt đầu)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.startOf("day"),
                        },
                      },
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Ngày kiểm kê (kết thúc)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.endOf("day"),
                        },
                      },
                    },
                  },
                },
              },
              "items.productId": {
                type: "XAutocomplete",
                attrs: {
                  label: "Sản phẩm",
                  "item-value": "_id",
                  "item-text": "sku",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("products", "findAll", {
                          payload: JSON.stringify({
                            search,
                            limit,
                            filters: [
                              {
                                key: "countryId",
                                operator: "equal_to",
                                value: country._id,
                              },
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
            },
          },
          topActionButtons: {
            import: userManager.checkRole(["kt", "qlk", "vdl", "vd"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Nhập Excel",
                icon: "mdi-upload",
              },
              target: {
                tooltip: { content: { text: "Nhập Excel" } },
                dialog: {
                  handlers: {
                    initialize() {
                      this.options.content.buttons.save.states.enabled = new DataContainer(false);
                    },
                  },
                  content: {
                    toolbar: {
                      title: "Nhập Excel",
                    },
                    content: {
                      type: "XForm",
                      makeAttrs(attrs, dialog) {
                        attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                        attrs.xContext = dialog;
                        attrs.xData = new DataContainer({});
                        return attrs;
                      },
                      attrs: {
                        xOptions: {
                          content: {
                            sections: {
                              default: {
                                fields: {
                                  file: {
                                    type: "file",
                                    attrs: {
                                      label: "File",
                                      required: true,
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    buttons: {
                      save: {
                        content: {
                          text: "Tải lên",
                        },
                        states: {},
                        on: {
                          async xClick({ self }) {
                            const dialog = self.context();
                            const btn = dialog.context();
                            const dataTable = btn.context();
                            const form = dialog.contentContainer.value;
                            const data = form.getData();
                            self.loading.value = true;
                            const result = await dataTable.execute(
                              async () => {
                                return await coreApiClient.call(
                                  "warehouseResetCommands",
                                  "importPrepare",
                                  {
                                    countryId: country._id,
                                  },
                                  data
                                );
                              },
                              undefined,
                              {
                                disableLoading: true,
                                willRefresh: true,
                              }
                            );
                            self.loading.value = false;
                            if (result) {
                              dialog.hide();
                              self2.importItems = result;
                              self2.importName = data.name;
                              self2.insertDialogContainer.value.show();
                            }
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            insert: userManager.checkRole(["kt", "qlk", "vdl", "vd"]) && {
              target: {
                dialog: {
                  attrs: {
                    width: "80%",
                  },
                  content: {
                    content: {
                      attrs: {
                        xOptions: {
                          content: {
                            fields: {
                              items: {
                                ext: {
                                  defaultValue() {
                                    return self.importItems;
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                  on: {
                    xHidden() {
                      self.importName = "";
                      self.importItems = [];
                    },
                  },
                  ext: {
                    container: self.insertDialogContainer,
                  },
                },
              },
            },
            export: userManager.checkRole(["kt", "qlk", "vdl", "vd"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Xuất Excel",
                icon: "mdi-microsoft-excel",
              },
              on: {
                xClick({ self }) {
                  const dataTable = self.context();
                  let findAllOptions = dataTable.getFindAllOptions();
                  if (dataTable.model.value.length) {
                    findAllOptions = {
                      payload: JSON.stringify({
                        filters: [
                          {
                            key: "_id",
                            operator: "in",
                            value: dataTable.model.value.map((item) => item._id),
                          },
                        ],
                        limit: -1,
                      }),
                    };
                  }
                  coreApiClient.download("warehouseResetCommands", `@/export`, findAllOptions);
                },
              },
            },
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["kt", "qlk", "vdl", "vd"]) && {
                target: {
                  dialog: {
                    attrs: {
                      width: "80%",
                    },
                    ext: {
                      subTitleMaker: (item) => `${item.name} - ${new Date(item.time).toLocaleString()} }`,
                    },
                  },
                },
              },
              delete: userManager.checkRole(["admin"]) && {},
            },
          },
          findAllOptionsFilter(options) {
            options.filters.push({
              key: "countryId",
              operator: "equal_to",
              value: this.context().$route.params.countryId,
            });
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("warehouseResetCommands", "findAll", options);
              return [items, count];
            },
            async insert(item, dataTable) {
              item.countryId = dataTable.context().$route.params.countryId;
              return await coreApiClient.call("warehouseResetCommands", "create", undefined, item);
            },
            async update(item) {
              return await coreApiClient.call(
                "warehouseResetCommands",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("warehouseResetCommands", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              warehouseId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Kho",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("warehouses", "findAll", {
                          payload: JSON.stringify({
                            search,
                            limit,
                            filters: [
                              {
                                key: "countryId",
                                operator: "equal_to",
                                value: country._id,
                              },
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                ext: {
                  colLength: 4,
                },
              },
              time: {
                type: "XTimePicker",
                attrs: {
                  label: "Thời gian kiểm kê",
                  required: true,
                },
                ext: {
                  colLength: 4,
                },
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Mô tả",
                },
                ext: {
                  colLength: 4,
                },
              },
              items: {
                type: "XArrayInput",
                attrs: {
                  label: "Danh sách sản phẩm",
                  xOptions: {
                    content: {
                      itemLabel: (item) => {
                        return `Chênh lệch: ${item.deltaCount}`;
                      },
                      itemProperties: {
                        productId: {
                          type: "XAutocomplete",
                          attrs: {
                            label: "Sản phẩm",
                            required: true,
                            "item-value": "_id",
                            "item-text": "sku",
                            clearable: true,
                            xOptions: {
                              content: {
                                async itemsSuggestor(search, value, limit) {
                                  const { items } = await coreApiClient.call("products", "findAll", {
                                    payload: JSON.stringify({
                                      search,
                                      limit,
                                      filters: [
                                        {
                                          key: "countryId",
                                          operator: "equal_to",
                                          value: country._id,
                                        },
                                        {
                                          key: "_id",
                                          operator: "equal_to",
                                          value,
                                        },
                                      ],
                                    }),
                                  });
                                  return items;
                                },
                              },
                            },
                          },
                        },
                        count: {
                          type: "number",
                          attrs: {
                            label: "Số lượng",
                          },
                        },
                      },
                      template: {
                        formOptions: {
                          content: {
                            colLength: 6,
                            ignoreOtherFields: false,
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            insertForm: userManager.checkRole(["kt", "qlk", "vdl", "vd"]) && {
              content: {
                fieldNames: ["description", "time", "warehouseId", "items"],
              },
            },
            editForm: userManager.checkRole(["kt", "qlk", "vdl", "vd"]) && {
              content: {
                fieldNames: ["description", "warehouseId", "items"],
              },
            },
            delete: userManager.checkRole(["admin"]) && {
              confirmDisplayField: "_id",
            },
          },
        },
      };
    },
  },
  async created() {
    this.country = await coreApiClient.call("countries", "findById", {
      id: this.$route.params.countryId,
    });
    this.tableOptions = this.makeTableOptions(this.country);
  },
});
